import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../contexts/UserContext';
import api from '../utils/api';

const PaymentResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, setUser } = useUser();
  const params = new URLSearchParams(location.search);
  const status = params.get('status');
  const message = params.get('message');

  useEffect(() => {
    const updateUserDetails = async () => {
      if (status === 'success') {
        try {
          const response = await api.get('/api/user-details', {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (response.status === 200) {
            setUser(response.data.user);
            localStorage.setItem('user', JSON.stringify(response.data.user));
          }
        } catch (error) {
          console.error('Update user details error:', error);
        }
      }
    };

    updateUserDetails();

    const timer = setTimeout(() => {
      navigate('/settings');
    }, 3000); // Redirect after 3 seconds

    return () => clearTimeout(timer);
  }, [navigate, status, token, setUser]);

  return (
    <div className="payment-result">
      {status === 'success' ? (
        <FontAwesomeIcon icon={faCheckCircle} color="#27ae60" size="3x" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} color="#e74c3c" size="3x" />
      )}
      <h2>{message}</h2>
      <p>سيتم إعادة توجيهك إلى صفحة الإعدادات خلال ثوانٍ...</p>
    </div>
  );
};

export default PaymentResult;