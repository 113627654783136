// NavigationBar.jsx
import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import './NavigationBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCog, faMoon, faSun, faSignOutAlt, faUsers, faSpinner, faUser, faHome, faInfoCircle, faBook, faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const NavigationBar = () => {
  const { user, logout } = useContext(UserContext);
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating logout delay
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <nav className={`navigation-bar ${darkMode ? 'dark-mode' : ''}`} aria-label="Navigation Bar">
      <ul>
        {user ? (
          <>
            <li className="nav-left">
              <button
                onClick={handleLogout}
                className="logout-button"
                aria-label="تسجيل الخروج"
                disabled={isLoggingOut}
              >
                {isLoggingOut ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faSignOutAlt} />
                )}
                <span>{isLoggingOut ? 'جاري تسجيل الخروج...' : 'تسجيل الخروج'}</span>
              </button>
            </li>
            
            <li className="nav-left">
              <button
                onClick={toggleDarkMode}
                className="theme-toggle"
                aria-label={darkMode ? 'تفعيل وضع النهار' : 'تفعيل الوضع الليلي'}
              >
                <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                <span>{darkMode ? 'وضع النهار' : 'الوضع الليلي'}</span>
              </button>
            </li>
            <li className={`nav-left ${location.pathname === '/settings' ? 'active' : ''}`}>
              <Link to="/settings" role="tab" aria-selected={location.pathname === '/settings'}>
                <FontAwesomeIcon icon={faCog} />
                <span>الإعدادات</span>
              </Link>
            </li>
            <li className={`nav-left ${location.pathname === '/group-chats' ? 'active' : ''}`}>
              <Link to="/group-chats" role="tab" aria-selected={location.pathname === '/group-chats'}>
                <FontAwesomeIcon icon={faUsers} />
                <span>المجموعات</span>
              </Link>
            </li>
            <li className={`nav-left ${location.pathname === '/chat' ? 'active' : ''}`}>
              <Link to="/chat" role="tab" aria-selected={location.pathname === '/chat'}>
                <FontAwesomeIcon icon={faComments} />
                <span> المعلم الذكي</span>
              </Link>
            </li>
          </>
        ) : (
          <li className="nav-left dropdown">
            <button className="auth-button">
              <FontAwesomeIcon icon={faUser} />
              <span>الحساب</span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link to="/login">
                  <FontAwesomeIcon icon={faSignInAlt} />
                  <span>تسجيل الدخول</span>
                </Link>
              </li>
              
              <li>
                <Link to="/register">
                  <FontAwesomeIcon icon={faUserPlus} />
                  <span>إنشاء حساب</span>
                </Link>
              </li>
            </ul>
          </li>
        )}
          
        <li className="nav-right">
          <Link to="/" className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faHome} />
            <span>الرئيسية</span>
          </Link>
        </li>
       
      </ul>
    </nav>
  );
};

export default NavigationBar;
