// src/contexts/UserContext.js

import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import api from '../utils/api';
import { toast } from 'react-toastify';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [groups, setGroups] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);

  useEffect(() => {
    tryAutoLogin();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post('/api/login', { email, password });
      if (response.status === 200) {
        setUser(response.data.user);
        setToken(response.data.token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        toast.success('تم تسجيل الدخول بنجاح');
        return { success: true };
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || 'فشل تسجيل الدخول. يرجى التحقق من بيانات الاعتماد والمحاولة مرة أخرى.');
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل تسجيل الدخول. يرجى المحاولة مرة أخرى.'
      };
    }
    return { success: false, error: 'فشل تسجيل الدخول. يرجى المحاولة مرة أخرى.' };
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    toast.info('تم تسجيل الخروج');
  };

  const tryAutoLogin = () => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    if (storedToken && storedUser) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser));
      setIsLoggedIn(true);
    }
  };

  const forgotPassword = async (email) => {
    try {
      const response = await api.post('/api/forgot-password', { email });
      if (response.status === 200) {
        toast.success('تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور');
        return { success: true };
      }
    } catch (error) {
      console.error('Forgot password error:', error);
      toast.error(error.response?.data?.message || 'فشل في إرسال بريد إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.');
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في إرسال بريد إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.'
      };
    }
    return { success: false, error: 'فشل في إرسال بريد إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.' };
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await api.post('/api/reset-password', { token, newPassword });
      if (response.status === 200) {
        toast.success('تم إعادة تعيين كلمة المرور بنجاح');
        return { success: true };
      }
    } catch (error) {
      console.error('Reset password error:', error);
      toast.error(error.response?.data?.message || 'فشل في إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.');
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.'
      };
    }
    return { success: false, error: 'فشل في إعادة تعيين كلمة المرور. يرجى المحاولة مرة أخرى.' };
  };

  const createGroup = async (name, subject) => {
    try {
      const response = await api.post('/api/create', { name, subject }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 201) {
        setGroups(prevGroups => [...prevGroups, response.data]);
        toast.success('تم إنشاء المجموعة بنجاح');
        return { success: true, group: response.data };
      }
    } catch (error) {
      console.error('Create group error:', error);
      toast.error(error.response?.data?.message || 'فشل في إنشاء المجموعة. يرجى المحاولة مرة أخرى.');
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في إنشاء المجموعة. يرجى المحاولة مرة أخرى.'
      };
    }
    return { success: false, error: 'فشل في إنشاء المجموعة. يرجى المحاولة مرة أخرى.' };
  };

  const fetchAvailableGroups = useCallback(async () => {
    try {
      const response = await api.get('/api/available', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        setAvailableGroups(response.data);
      }
    } catch (error) {
      console.error('Fetch available groups error:', error);
      toast.error('فشل في جلب المجموعات المتاحة.');
    }
  }, [token]);

  const joinGroup = async (groupId) => {
    try {
      const response = await api.post(`/api/join/${groupId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        fetchAvailableGroups(); // Refresh the list of available groups
        // Removed toast here to prevent duplicate notifications
        return { success: true, message: response.data.message };
      }
    } catch (error) {
      console.error('Join group error:', error);
      // Removed toast here to prevent duplicate notifications
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في الانضمام إلى المجموعة. يرجى المحاولة مرة أخرى.'
      };
    }
    return { success: false, error: 'فشل في الانضمام إلى المجموعة. يرجى المحاولة مرة أخرى.' };
  };

  // Remove User from Group
  const removeUserFromGroup = async (groupId, username) => {
    try {
      const response = await api.delete(`/api/remove/${groupId}/${username}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        fetchAvailableGroups();
        // Removed toast here to prevent duplicate notifications
        return { success: true, message: response.data.message };
      }
    } catch (error) {
      console.error('Remove user error:', error);
      // Removed toast here to prevent duplicate notifications
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في إزالة المستخدم من المجموعة.'
      };
    }
    return { success: false, error: 'فشل في إزالة المستخدم من المجموعة.' };
  };

  // Block User from Group
  const blockUserFromGroup = async (groupId, username) => {
    try {
      const response = await api.post(`/api/block/${groupId}/${username}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        fetchAvailableGroups();
        // Removed toast here to prevent duplicate notifications
        return { success: true, message: response.data.message };
      }
    } catch (error) {
      console.error('Block user error:', error);
      // Removed toast here to prevent duplicate notifications
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في حظر المستخدم من المجموعة.'
      };
    }
    return { success: false, error: 'فشل في حظر المستخدم من المجموعة.' };
  };

  // Update Group Settings
  const updateGroupSettings = async (groupId, isPublic) => {
    try {
      const response = await api.put(`/api/update/${groupId}`, { isPublic }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        fetchAvailableGroups();
        // Removed toast here to prevent duplicate notifications
        return { success: true, group: response.data };
      }
    } catch (error) {
      console.error('Update group settings error:', error);
      // Removed toast here to prevent duplicate notifications
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في تحديث إعدادات المجموعة.'
      };
    }
    return { success: false, error: 'فشل في تحديث إعدادات المجموعة.' };
  };

  // Delete Group
  const deleteGroup = async (groupId) => {
    try {
      const response = await api.delete(`/api/delete/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        fetchAvailableGroups();
        // Removed toast here to prevent duplicate notifications
        return { success: true, message: response.data.message };
      }
    } catch (error) {
      console.error('Delete group error:', error);
      // Removed toast here to prevent duplicate notifications
      return { 
        success: false, 
        error: error.response?.data?.message || 'فشل في حذف المجموعة.'
      };
    }
    return { success: false, error: 'فشل في حذف المجموعة.' };
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser,
      token, 
      isLoggedIn, 
      login, 
      logout, 
      groups, 
      availableGroups, 
      forgotPassword,
      resetPassword,
      createGroup, 
      fetchAvailableGroups, 
      joinGroup,
      removeUserFromGroup,
      blockUserFromGroup,
      updateGroupSettings,
      deleteGroup
    }}>
      {children}
    </UserContext.Provider>
  );
};
