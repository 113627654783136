import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomeScreen.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeScreen = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <div className="home-container">
      <header className="hero" data-aos="fade-down" data-aos-duration="1500">
        <h1 data-aos="zoom-in" data-aos-delay="300">مرحبًا بك في منصة مُعين</h1>
        <p data-aos="fade-up" data-aos-delay="600">منصة تعليمية ذكية لمساعدتك في رحلتك التعليمية</p>
      </header>

      <section className="features" data-aos="fade-up" data-aos-duration="1200">
        <h2 data-aos="fade-down">ميزات مُعين</h2>
        <div className="feature-grid">
          
          <div className="feature-item" data-aos="flip-left" data-aos-delay="400">
            <h3>محادثات تفاعلية</h3>
            <p>استعن بمختصين أو زملاء لحل مسائل معقدة</p>
          </div>
          <div className="feature-item" data-aos="flip-left" data-aos-delay="600">
            <h3>تدرب على الإمتحانات</h3>
            <p>  فقط اطلب من المعلم الذكي أن يقوم بإعداد سؤال و شرح الجواب لك من إمتحانات سابقة</p>
          </div>
          <div className="feature-item" data-aos="flip-left" data-aos-delay="200">
            <h3>تعلم ذكي</h3>
            <p>استفد من أحدث تقنيات الذكاء الاصطناعي لتحسين تجربة التعلم الخاصة بك لكل المواد الدراسية</p>
          </div>
        </div>
      </section>

      <section className="about" data-aos="fade-up" data-aos-duration="1200">
        <h2 data-aos="fade-down">عن مُعين</h2>
        <p data-aos="fade-up" data-aos-delay="300">مُعين هي منصة تعليمية  تهدف إلى تمكين الطلاب من جميع الأعمار لتحقيق أهدافهم التعليمية. نحن نجمع بين التكنولوجيا المتقدمة بالذكاء الإصطناعي و التفاعل من المختصين لتوفير تجربة تعلم فريدة.</p>
      </section>

      <section className="cta" data-aos="fade-up" data-aos-duration="1200">
        <h2 data-aos="zoom-in">ابدأ رحلتك التعليمية اليوم</h2>
        <Link to="/courses" className="cta-button" data-aos="zoom-in" data-aos-delay="300">استكشف الدورات</Link>
      </section>

      <footer className="footer">
        <div className="footer-content" data-aos="fade-up" data-aos-duration="1200">
          <div className="footer-section" data-aos="fade-right" data-aos-delay="200">
            <h3>تواصل معنا</h3>
            <p>البريد الإلكتروني: info@mo3in.com</p>
            <p>الهاتف: 900000000</p>
          </div>
          <div className="footer-section" data-aos="fade-up" data-aos-delay="400">
            <h3>روابط سريعة</h3>
            <ul>
              <li><Link to="/about">عن مُعين</Link></li>
              <li><Link to="/courses">الدورات</Link></li>
              <li><Link to="/faq">الأسئلة الشائعة</Link></li>
            </ul>
          </div>
          <div className="footer-section" data-aos="fade-left" data-aos-delay="600">
            <h3>تابعنا</h3>
            <div className="social-icons">
              {/* Add your social media icons here */}
            </div>
          </div>
        </div>
        <div className="footer-bottom" data-aos="fade-up" data-aos-delay="800">
          <p>&copy; 2024 مُعين. جميع الحقوق محفوظة.</p>
          <Link to="/privacy">سياسة الخصوصية</Link>
          <Link to="/terms">شروط الاستخدام</Link>
        </div>
      </footer>
    </div>
  );
};

export default HomeScreen;