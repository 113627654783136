import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PhotoViewModal.css';

const PhotoViewModal = ({ imageUrl, onClose }) => {
  return (
    <div className="photo-view-modal">
      <div className="modal-content">
        <img src={imageUrl} alt="Full size" className="full-size-image" />
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export default PhotoViewModal;