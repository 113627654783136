import axios from 'axios';


const api = axios.create({
  baseURL: 'https://mo3in.com', 
  // baseURL: 'http://192.168.100.56:6001', 
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    console.log('Request:', config.method.toUpperCase(), config.url);
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Request headers:', config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Response:', response.status, response.config.url);
    return response;
  },
  (error) => {
    console.error('Response error:', error.response ? error.response.status : error.message, error.config.url);
    return Promise.reject(error);
  }
);

// Add this method if it doesn't exist
api.delete = (url) => {
  return api({
    method: 'delete',
    url: url,
  });
};

export const home_url = 'https://mo3in.com/settings'; // or whatever your home URL is

export default api;