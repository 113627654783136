import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faRedo } from '@fortawesome/free-solid-svg-icons';
import api from '../utils/api';
import './OtpVerificationScreen.css';

const OtpVerificationScreen = () => {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [secondsRemaining, setSecondsRemaining] = useState(120);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  useEffect(() => {
    if (!email) {
      navigate('/register');
    } else {
      generateOtp();
    }
  }, [email, navigate]);

  const stopTimer = useCallback(() => {
    setSecondsRemaining(0);
  }, []);

  useEffect(() => {
    if (secondsRemaining <= 0) return;

    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [secondsRemaining]);

  const generateOtp = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      await api.post('/api/generate-otp', { email });
      setSecondsRemaining(120);
    } catch (error) {
      setErrorMessage('فشل في إرسال رمز التحقق. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      await api.post('/api/verify-otp', { email, otp });
      setSuccessMessage('تم التحقق بنجاح');
      stopTimer();
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      setErrorMessage('رمز التحقق غير صحيح. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="otp-verification-container">
      <motion.div
        className="otp-verification-card"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>تأكيد رمز التحقق</h2>
        <p>تم إرسال رمز التحقق إلى بريدك الإلكتروني</p>
        <form onSubmit={verifyOtp}>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}
          <div className="input-group">
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="رمز التحقق"
              required
            />
          </div>
          {secondsRemaining > 0 && (
            <p className="timer">
              ينتهي رمز التحقق في {Math.floor(secondsRemaining / 60)}:
              {(secondsRemaining % 60).toString().padStart(2, '0')}
            </p>
          )}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="verify-button"
            disabled={isLoading || successMessage}
          >
            {isLoading ? 'جاري التحقق...' : 'تأكيد'}
          </motion.button>
        </form>
        {secondsRemaining === 0 && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={generateOtp}
            className="resend-button"
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faRedo} />
            <span>إعادة إرسال رمز التحقق</span>
          </motion.button>
        )}
      </motion.div>
    </div>
  );
};

export default OtpVerificationScreen;