// SideDrawer.jsx
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { allOptions } from '../utils/subjectMapping';
import './SideDrawer.css';

const SideDrawer = ({ startNewChat, selectExistingChat, isOpen, onClose }) => {
  const { chatHistories, allChatHistories, fetchChatHistories, deleteChat, deleteAllChats, searchChatHistories } = useContext(ChatContext);
  const { darkMode } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasInitiallyFetched, setHasInitiallyFetched] = useState(false);

  const fetchChatHistoriesOnce = useCallback(() => {
    if (!hasInitiallyFetched) {
      fetchChatHistories();
      setHasInitiallyFetched(true);
    }
  }, [fetchChatHistories, hasInitiallyFetched]);

  useEffect(() => {
    if (isOpen) {
      fetchChatHistoriesOnce();
    }
  }, [isOpen, fetchChatHistoriesOnce]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    searchChatHistories(query);
  }, [searchChatHistories]);

  const handleDeleteChat = async (id, e) => {
    e.stopPropagation();
    try {
      await deleteChat(id);
    } catch (error) {
      console.error('Failed to delete chat:', error);
    }
  };

  const handleDeleteAllChats = async () => {
    if (window.confirm('هل أنت متأكد أنك تريد حذف جميع المحادثات؟ هذا الإجراء لا يمكن التراجع عنه.')) {
      try {
        await deleteAllChats();
      } catch (error) {
        console.error('Failed to delete all chats:', error);
      }
    }
  };

  const getArabicSubject = (englishSubject) => {
    return Object.entries(allOptions).find(([_, value]) => value === englishSubject)?.[0] || englishSubject;
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div className={`side-drawer ${isOpen ? 'open' : ''} ${darkMode ? 'dark-mode' : ''}`}>
      {isMobile && (
        <button className="close-drawer-button" onClick={onClose} aria-label="إغلاق القائمة">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      <div className="side-drawer-content">
        <div className="drawer-header">
          <h2>المعلم الذكي</h2>
          <div className="search-field">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              placeholder="بحث في المحادثات"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              aria-label="بحث في المحادثات"
            />
          </div>
        </div>
        <button className="new-chat-button" onClick={startNewChat} aria-label="محادثة جديدة">
          <FontAwesomeIcon icon={faPlus} /> محادثة جديدة
        </button>
        <div className="chat-history">
          {chatHistories.length > 0 ? (
            chatHistories.map((chat) => (
              <div
                key={chat.id}
                className="chat-history-item"
                onClick={() => selectExistingChat(chat.id, chat.subject)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') selectExistingChat(chat.id, chat.subject);
                }}
              >
                <p className="chat-title">
                  {chat.chats && chat.chats.length > 0
                    ? chat.chats[0].question
                    : 'لا توجد رسائل'}
                </p>
                <p className="chat-subject">({getArabicSubject(chat.subject)})</p>
                <button
                  className="delete-chat-button"
                  onClick={(e) => handleDeleteChat(chat.id, e)}
                  aria-label="حذف المحادثة"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ))
          ) : (
            <p className="no-chats">لا توجد محادثات متاحة.</p>
          )}
        </div>
      </div>
      <div className="side-drawer-footer">
        <button className="delete-all-chats" onClick={handleDeleteAllChats} aria-label="حذف جميع المحادثات">
          حذف جميع المحادثات
        </button>
      </div>
    </div>
  );
};

export default React.memo(SideDrawer);
