// ProfileAndPaymentTab.jsx
import React from 'react';
import ProfileTab from './ProfileTab';
import PaymentTab from './PaymentTab';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useContext } from 'react';
import './ProfileAndPaymentTab.css';

const ProfileAndPaymentTab = ({ userProfile, classOptions, subscriptionStatus, paymentHistory, onRefresh }) => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className={`profile-payment-tab ${darkMode ? 'dark-mode' : ''}`}>
      <div className="profile-section">
        <ProfileTab
          userProfile={userProfile}
          classOptions={classOptions}
          onRefresh={onRefresh}
        />
      </div>
      <div className="payment-section">
        <PaymentTab
          subscriptionStatus={subscriptionStatus}
          paymentHistory={paymentHistory}
          onRefresh={onRefresh}
        />
      </div>
    </div>
  );
};

export default ProfileAndPaymentTab;
