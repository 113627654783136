import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import api from '../utils/api';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const { token, user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setDarkMode(user.isDark);
    }
  }, [user]);

  const toggleDarkMode = async () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    
    try {
      const response = await api.put('/api/updateIsDark', { isDark: newDarkMode }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        setUser(prevUser => ({ ...prevUser, isDark: newDarkMode }));
      }
    } catch (error) {
      console.error('Update dark mode error:', error);
      // Revert the change if the API call fails
      setDarkMode(!newDarkMode);
    }
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};