// ProfileTab.jsx
import React, { useState, useContext } from 'react'; 
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import DeleteAccountModal from '../../components/DeleteAccountModal';
import { UserContext } from '../../contexts/UserContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import './ProfileTab.css';

const ProfileTab = ({ userProfile, classOptions, onRefresh }) => {
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  const { darkMode } = useContext(ThemeContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userProfile.firstName || '',
    lastName: userProfile.lastName || '',
    username: userProfile.username || '',
    email: userProfile.email || '',
    classs: userProfile.classs || '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put('/api/profile', formData);
      setIsEditing(false);
      onRefresh();
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await api.delete('/api/profile');
      logout(); // This should remove the token and update the user context
      navigate('/'); // Navigate to home page after logout
    } catch (error) {
      console.error('Failed to delete account:', error);
    }
  };

  return (
    <div className={`profile-tab ${darkMode ? 'dark-mode' : ''}`}>
      <h2>معلومات الحساب الشخصي</h2>
      <form onSubmit={handleSubmit} className="profile-form">
        <div className="form-group">
          <label htmlFor="firstName">الاسم الأول</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            disabled={!isEditing}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">اسم العائلة</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            disabled={!isEditing}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">اسم المستخدم</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            disabled={!isEditing}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">البريد الإلكتروني</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            disabled={!isEditing}
            className="profile-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="classs">الصف</label>
          <select
            id="classs"
            name="classs"
            value={formData.classs}
            onChange={handleInputChange}
            disabled={!isEditing}
            className="profile-select"
          >
            {classOptions.map(option => (
              <option key={option.value} value={option.value}>{option.name}</option>
            ))}
          </select>
        </div>
        {isEditing ? (
          <div className="button-group">
            <button type="submit" className="btn-primary">حفظ التغييرات</button>
            <button type="button" className="btn-secondary" onClick={() => setIsEditing(false)}>إلغاء</button>
          </div>
        ) : (
          <button type="button" className="btn-primary" onClick={() => setIsEditing(true)}>تعديل الملف الشخصي</button>
        )}
      </form>
      <button onClick={() => setIsDeleteModalOpen(true)} className="btn-danger">حذف الحساب</button>
      <DeleteAccountModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAccount}
      />
    </div>
  );
};

export default ProfileTab;
