import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, UserContext } from './contexts/UserContext';
import { ChatProvider } from './contexts/ChatContext';
import { ThemeProvider, ThemeContext } from './contexts/ThemeContext';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ChatScreen from './components/ChatScreen';
import NavigationBar from './components/NavigationBar';
import SettingsScreen from './screens/SettingsScreen';
import OtpVerificationScreen from './screens/OtpVerificationScreen';
import PaymentResult from './components/PaymentResult';
import GroupChatScreen from './screens/GroupChatScreen';
import HomeScreen from './screens/HomeScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';

function ProtectedRoute({ children }) {
  const { user } = useContext(UserContext);
  return user ? children : <Navigate to="/" replace />;
}

function AppContent() {
  const { user } = useContext(UserContext);
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <NavigationBar />
      <div className="content-container">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/login" element={user ? <Navigate to="/chat" replace /> : <LoginScreen />} />
          <Route path="/register" element={user ? <Navigate to="/chat" replace /> : <RegisterScreen />} />
          <Route path="/chat" element={<ProtectedRoute><ChatScreen /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><SettingsScreen /></ProtectedRoute>} />
          <Route path="/selection" element={<Navigate to="/chat" replace />} />
          <Route path="/otp-verification" element={<OtpVerificationScreen />} />
          <Route path="/group-chats" element={<ProtectedRoute><GroupChatScreen /></ProtectedRoute>} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/reset-password/:token" element={<ResetPasswordScreen />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <UserProvider>
        <ChatProvider>
          <ThemeProvider>
            <AppContent />
            {/* The payment result route is placed here to prevent navigation bar interference */}
            <Routes>
              <Route path="/payment-result" element={<PaymentResult />} />
            </Routes>
          </ThemeProvider>
        </ChatProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
