// ChatScreen.jsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ChatContext } from '../contexts/ChatContext';
import { ThemeContext } from '../contexts/ThemeContext';
import api from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faImage, faPaperPlane, faThumbsUp, faThumbsDown, faCopy, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import SideDrawer from './SideDrawer';
import './ChatScreen.css';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import PhotoViewModal from './PhotoViewModal';

const ChatScreen = () => {
  const { user } = useContext(UserContext);
  const { startNewChat, addChatEntry, getChatHistoryById, toggleLike, fetchSelectedSubjects, selectedSubjects, uploadPhoto, allChatHistories } = useContext(ChatContext);
  const { darkMode } = useContext(ThemeContext);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [currentChatEntries, setCurrentChatEntries] = useState([]);
  const [streamedAnswer, setStreamedAnswer] = useState('');
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [message, setMessage] = useState('');
  const scrollRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    fetchSelectedSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      loadExistingChat();
    } else {
      setIsLoading(false);
    }
  }, [selectedSubject, currentChatId]);

  useEffect(() => {
    scrollToBottom();
  }, [currentChatEntries, streamedAnswer]);

  useEffect(() => {
    // Prevent body scrolling when ChatScreen is mounted
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable body scrolling when ChatScreen is unmounted
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  const loadExistingChat = async () => {
    if (selectedSubject && currentChatId) {
      try {
        setIsLoading(true);
        // First, try to find the chat in allChatHistories
        const existingChat = allChatHistories.find(chat => chat.id === currentChatId);
        if (existingChat) {
          const sortedChats = existingChat.chats
            .map(chat => ({
              ...chat,
              _id: chat._id || chat.id,
              showAiTitle: true,
            }))
            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          setCurrentChatEntries(sortedChats);
        } else {
          // If not found in allChatHistories, fetch from the server
          const fetchedChat = await getChatHistoryById(currentChatId);
          if (fetchedChat && fetchedChat.chats) {
            const sortedChats = fetchedChat.chats
              .map(chat => ({
                ...chat,
                _id: chat._id || chat.id,
                showAiTitle: true,
              }))
              .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
            setCurrentChatEntries(sortedChats);
          } else {
            setCurrentChatEntries([]);
          }
        }
      } catch (error) {
        console.error('Failed to load existing chat:', error);
        setCurrentChatEntries([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject);
    setCurrentChatId(null);
    setCurrentChatEntries([]);
  };

  const handleSendMessage = async () => {
    if (!message.trim() && !currentPhoto) {
      alert('Please enter a question or upload an image.');
      return;
    }

    if (!selectedSubject) {
      alert('Please select a subject.');
      return;
    }

    const questionText = message.trim() || (currentPhoto ? 'اشرح' : '');

    const tempId = Date.now().toString();
    const tempEntry = {
      id: tempId,
      question: questionText,
      answer: '',
      timestamp: new Date(),
      imageUrl: previewImage,
    };

    setIsTyping(true);
    setStreamedAnswer('');
    setIsStreaming(false);
    setCurrentChatEntries(prev => [...prev, tempEntry]);

    setMessage('');
    const photoToSend = currentPhoto;
    setCurrentPhoto(null);
    setPreviewImage(null);

    try {
      let newChatData;
      if (!currentChatId) {
        newChatData = await startNewChat(selectedSubject, questionText, photoToSend);
        setCurrentChatId(newChatData.chatId);
      } else {
        newChatData = await addChatEntry(currentChatId, questionText, photoToSend);
      }

      setIsTyping(false);
      setIsStreaming(true);

      const newEntry = {
        _id: newChatData.newChatId,
        id: newChatData.newChatId || tempId,
        question: questionText,
        answer: '',
        timestamp: new Date(),
        imageUrl: newChatData.imageUrl,
        showAiTitle: true,
      };

      setCurrentChatEntries(prev => 
        prev.map(e => e.id === tempId ? newEntry : e)
      );

      await simulateStreamingEffect(newChatData.answer, newEntry);

      setCurrentChatEntries(prev =>
        prev.map(e =>
          e.id === newEntry.id ? { ...e, answer: newChatData.answer, showAiTitle: true } : e
        )
      );
    } catch (error) {
      console.error('Failed to send message:', error);
      setCurrentChatEntries(prev => prev.filter(e => e.id !== tempId));
      alert(error.message || 'Failed to send message. Please try again.');
    } finally {
      setIsTyping(false);
      setIsStreaming(false);
      setStreamedAnswer('');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const simulateStreamingEffect = async (answer, entry) => {
    const chunkSize = 3; // Adjust this value to control streaming speed
    for (let i = 0; i < answer.length; i += chunkSize) {
      await new Promise(resolve => setTimeout(resolve, 50)); // Adjust timeout for speed
      const chunk = answer.slice(0, i + chunkSize);
      setStreamedAnswer(chunk);
      updateStreamedAnswer(chunk, entry);
    }
  };

  const updateStreamedAnswer = (streamedAnswer, entry) => {
    setStreamedAnswer(streamedAnswer);
    setCurrentChatEntries(prev =>
      prev.map(e =>
        e.id === entry.id ? { ...e, answer: streamedAnswer } : e
      )
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentPhoto(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setCurrentPhoto(null);
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleImageClick = (imageUrl) => {
    if (imageUrl) {
      setSelectedImage(imageUrl);
    } else {
      // Trigger file input click when no imageUrl is provided
      fileInputRef.current.click();
    }
  };

  const closePhotoView = () => {
    setSelectedImage(null);
  };

  const handleToggleLike = async (entry, isLike) => {
    try {
      const chatId = currentChatId || entry.chatId; // Use entry.chatId as fallback
      if (!chatId) {
        console.error('Chat ID is undefined');
        return;
      }
      
      const entryId = entry._id || entry.id; // Use entry.id as fallback
      if (!entryId) {
        console.error('Entry ID is undefined');
        return;
      }

      console.log('Toggling like for chat history ID:', chatId);
      console.log('Toggling like for chat entry ID:', entryId);
      console.log('isLiked:', isLike);

      await toggleLike(chatId, entryId, isLike);
      
      setCurrentChatEntries(prev =>
        prev.map(e =>
          e._id === entryId || e.id === entryId
            ? { ...e, isLiked: isLike, isDisliked: !isLike, toggle: true }
            : e
        )
      );
    } catch (error) {
      console.error('Failed to update like/dislike status:', error);
      alert('Failed to update like/dislike status. Please try again.');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    });
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const getArabicSubject = (englishSubject) => {
    const subject = selectedSubjects.find(s => s.name.English === englishSubject);
    return subject ? subject.name.Arabic : englishSubject;
  };

  const renderTextWithLatex = (text) => {
    if (text.includes('$$') || text.includes('$') || text.includes('\\[') || text.includes('\\(')) {
      // If LaTeX is detected, render with Latex component
      return <Latex>{text}</Latex>;
    } else {
      // If no LaTeX, process bold text
      const parts = text.split(/(\*\*.*?\*\*)/);
      return (
        <>
          {parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={index}>{part.slice(2, -2)}</strong>;
            }
            return part;
          })}
        </>
      );
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className={`chat-screen ${darkMode ? 'dark-mode' : ''}`}>
      <button className="toggle-drawer-button" onClick={toggleSideDrawer}>
        <FontAwesomeIcon icon={isSideDrawerOpen ? faTimes : faBars} />
      </button>
      <SideDrawer
        startNewChat={() => {
          setSelectedSubject(null);
          setCurrentChatId(null);
          setCurrentChatEntries([]);
          if (isMobile) setIsSideDrawerOpen(false);
        }}
        selectExistingChat={(chatId, subject) => {
          setCurrentChatId(chatId);
          setSelectedSubject(subject);
          if (isMobile) setIsSideDrawerOpen(false);
        }}
        isOpen={!isMobile || isSideDrawerOpen}
        onClose={() => setIsSideDrawerOpen(false)}
      />
      <div className="chat-main">
        {selectedSubject ? (
          <div className="selected-subject">
            <h2>{getArabicSubject(selectedSubject)}</h2>
          </div>
        ) : (
          <div className="subject-selector">
            <select onChange={(e) => handleSubjectChange(e.target.value)} value={selectedSubject || ''}>
              <option value="">اختر المادة</option>
              {selectedSubjects.map((subject) => (
                <option key={subject.name.English} value={subject.name.English}>
                  {subject.name.Arabic}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="chat-container" ref={scrollRef}>
          {isLoading ? (
            <div className="loading">جاري التحميل...</div>
          ) : (
            <>
              {currentChatEntries.map((entry, index) => (
                <div key={entry._id || entry.id} className="message-container">
                  <div className="user-message">
                    <h3 className="message-title">الطالب</h3>
                    {entry.imageUrl && (
                      <img
                        src={entry.imageUrl}
                        alt="User uploaded"
                        className="message-image"
                        onClick={() => handleImageClick(entry.imageUrl)}
                      />
                    )}
                    <div className="message-content">{renderTextWithLatex(entry.question)}</div>
                  </div>
                  {(entry.answer || isStreaming) && (
                    <div className="ai-message">
                      <h3 className="message-title">المعلم الرقمي</h3>
                      <div className="message-content">
                        {isStreaming && entry.id === currentChatEntries[currentChatEntries.length - 1].id
                          ? renderTextWithLatex(streamedAnswer)
                          : renderTextWithLatex(entry.answer)}
                      </div>
                      {!isStreaming && (
                        <div className="message-actions">
                          <button 
                            onClick={() => handleToggleLike(entry, true)} 
                            className={entry.isLiked ? 'active' : ''}
                            aria-label="Like"
                          >
                            <FontAwesomeIcon icon={faThumbsUp} />
                          </button>
                          <button 
                            onClick={() => handleToggleLike(entry, false)} 
                            className={entry.isDisliked ? 'active' : ''}
                            aria-label="Dislike"
                          >
                            <FontAwesomeIcon icon={faThumbsDown} />
                          </button>
                          <button onClick={() => copyToClipboard(entry.answer)} aria-label="Copy">
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {isTyping && (
                <div className="message-container">
                  <div className="ai-message">
                    <h3 className="message-title">المعلم الرقمي</h3>
                    <div className="typing-animation">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="input-area-wrapper">
          {previewImage && (
            <div className="image-preview">
              <img src={previewImage} alt="Preview" className="preview-image" />
              <button onClick={handleRemoveImage} className="remove-image-button" aria-label="Remove Image">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
          <div className="input-area">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: 'none' }}
            />
            <button onClick={() => handleImageClick()} className="upload-button" aria-label="Upload Image">
              <FontAwesomeIcon icon={faImage} />
            </button>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              // placeholder="اكتب رسالتك هنا..."
              rows="1"
            />
            <button onClick={handleSendMessage} disabled={isTyping || !selectedSubject} className="send-button" aria-label="Send Message">
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
      {selectedImage && (
        <PhotoViewModal imageUrl={selectedImage} onClose={closePhotoView} />
      )}
    </div>
  );
};

export default ChatScreen;
