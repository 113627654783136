// src/components/GroupChatScreen.js

import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ChatContext } from '../contexts/ChatContext';
import { ThemeContext } from '../contexts/ThemeContext';
import api from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './GroupChatScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPaperPlane, 
  faCopy, 
  faChevronDown, 
  faChevronUp, 
  faUsers, 
  faComments,
  faBars, // Import faBars for the drawer toggle
  faTimes  // Import faTimes for closing the drawer
} from '@fortawesome/free-solid-svg-icons';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const GroupChatScreen = () => {
  const { 
    user, 
    token, 
    availableGroups, 
    createGroup, 
    fetchAvailableGroups, 
    joinGroup,
    updateGroupSettings,
    removeUserFromGroup,
    blockUserFromGroup,
    deleteGroup
  } = useContext(UserContext);
  const { getAnswer } = useContext(ChatContext);
  const { darkMode } = useContext(ThemeContext);
  const scrollRef = useRef(null);

  // State Variables
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupSubject, setNewGroupSubject] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isGroupInfoExpanded, setIsGroupInfoExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('chat');
  const [isAskingTeacher, setIsAskingTeacher] = useState(false);
  
  // State for Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Polling Interval (in milliseconds)
  const POLLING_INTERVAL = 5000; // 5 seconds

  // Fetch Subjects
  const fetchSubjects = useCallback(async () => {
    try {
      const response = await api.get('/api/subjects', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        const allSubjects = [
          ...response.data.subjects.s1Subjects,
          ...response.data.subjects.s2Subjects,
          ...response.data.selectedSubjects
        ];
        const uniqueSubjects = Array.from(new Set(allSubjects.map(s => s.name.English)))
          .map(englishName => allSubjects.find(s => s.name.English === englishName));
        setSubjects(uniqueSubjects);
      }
    } catch (error) {
      console.error('Fetch subjects error:', error);
      toast.error('فشل في جلب المواد.');
    }
  }, [token]);

  useEffect(() => {
    if (subjects.length === 0) {
      fetchSubjects();
    }
  }, [fetchSubjects, subjects.length]);

  // Fetch Available Groups
  useEffect(() => {
    if (availableGroups.length === 0) {
      fetchAvailableGroups();
    }
  }, [fetchAvailableGroups, availableGroups.length]);

  // Handle Create Group
  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (!newGroupName.trim() || !newGroupSubject) {
      toast.error('الرجاء إدخال اسم المجموعة والمادة');
      return;
    }
    const result = await createGroup(newGroupName, newGroupSubject);
    if (result.success) {
      setNewGroupName('');
      setNewGroupSubject('');
      fetchAvailableGroups();
      handleSelectGroup(result.group);
      // Close drawer on mobile after creating a group
      if (window.innerWidth < 768) setIsDrawerOpen(false);
    }
    // Errors are handled in UserContext
  };

  // Helper function to get the English subject
  const getEnglishSubject = (arabicSubject) => {
    const subject = subjects.find(s => s.name.Arabic === arabicSubject);
    return subject ? subject.name.English : arabicSubject;
  };

  // Handle Join Group
  const handleJoinGroup = async (groupId) => {
    const result = await joinGroup(groupId);
    // Success and errors are handled in UserContext
    // Close drawer on mobile after joining
    if (window.innerWidth < 768) setIsDrawerOpen(false);
  };

  // Handle Select Group
  const handleSelectGroup = async (group) => {
    // Ensure that the group has a subject property
    if (!group.subject && group.subjectName) {
      group.subject = group.subjectName;
    }
    setSelectedGroup(group);
    setActiveTab('chat');
    setIsGroupInfoExpanded(false);
    try {
      const response = await api.get(`/api/${group._id}/messages`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessages(response.data.messages);
      // Close drawer on mobile after selecting a group
      if (window.innerWidth < 768) setIsDrawerOpen(false);
    } catch (error) {
      console.error('Fetch messages error:', error);
      toast.error('فشل في جلب الرسائل.');
    }
  };

  // Scroll to Bottom
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Polling Mechanism to Fetch New Messages
  useEffect(() => {
    if (!selectedGroup) return;

    // Function to fetch messages
    const fetchMessages = async () => {
      try {
        const response = await api.get(`/api/${selectedGroup._id}/messages`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.status === 200) {
          const fetchedMessages = response.data.messages;
          // Check if there are new messages
          if (fetchedMessages.length > messages.length) {
            setMessages(fetchedMessages);
            fetchAvailableGroups(); // Update group statistics
          }
        }
      } catch (error) {
        console.error('Polling fetch messages error:', error);
        toast.error('فشل في جلب الرسائل الجديدة.');
      }
    };

    // Initial fetch to ensure messages are up-to-date
    fetchMessages();

    // Set up the interval
    const intervalId = setInterval(fetchMessages, POLLING_INTERVAL);

    // Clean up the interval on component unmount or when selectedGroup changes
    return () => clearInterval(intervalId);
  }, [selectedGroup, token, messages.length, fetchAvailableGroups]);

  // Polling Mechanism to Fetch Available Groups for Updating Stats
  useEffect(() => {
    // Function to fetch available groups
    const fetchGroups = async () => {
      try {
        await fetchAvailableGroups();
      } catch (error) {
        console.error('Polling fetch groups error:', error);
        toast.error('فشل في تحديث قائمة المجموعات.');
      }
    };

    // Initial fetch
    fetchGroups();

    // Set up the interval
    const intervalId = setInterval(fetchGroups, POLLING_INTERVAL);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchAvailableGroups]);

  // Render Text with LaTeX and Markdown
  const renderTextWithLatex = (text) => {
    if (text.includes('$$') || text.includes('$') || text.includes('\\[') || text.includes('\\(')) {
      return <Latex>{text}</Latex>;
    } else {
      const parts = text.split(/(\*\*.*?\*\*)/);
      return (
        <>
          {parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={index}>{part.slice(2, -2)}</strong>;
            }
            return part;
          })}
        </>
      );
    }
  };

  // Handle Send Message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    if (!selectedGroup) {
      toast.error('الرجاء اختيار مجموعة قبل إرسال الرسالة.');
      return;
    }

    setIsTyping(true);
    try {
      let messageContent = newMessage;
      let isAIResponse = false;

      if (isAskingTeacher) {
        const question = newMessage.trim();
        if (!selectedGroup.subject) {
          toast.error('لم يتم العثور على مادة لهذه المجموعة.');
          setIsTyping(false);
          return;
        }
        const englishSubject = getEnglishSubject(selectedGroup.subject);
        const answerResponse = await getAnswer(question, englishSubject, selectedGroup._id);
        messageContent = `السؤال للمعلم الذكي: ${question}\nرد المعلم الذكي: ${answerResponse.result}`;
        isAIResponse = true;
      }

      const response = await api.post(`/api/${selectedGroup._id}/messages`, 
        { content: messageContent, isAIResponse },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessages(prevMessages => [...prevMessages, response.data]);
      setNewMessage('');
      setIsAskingTeacher(false);

      if (isAIResponse) {
        toast.info('تمت إضافة رد المعلم الذكي إلى الدردشة.');
      } else {
        // toast.success('تم إرسال الرسالة بنجاح');
      }
    } catch (error) {
      console.error('Send message error:', error);
      toast.error('فشل في إرسال الرسالة. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsTyping(false);
      scrollToBottom();
    }
  };

  // Copy to Clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('تم نسخ النص إلى الحافظة');
    }).catch(() => {
      toast.error('فشل في نسخ النص');
    });
  };

  // Handle Update Group Settings
  const handleUpdateGroupSettings = async (groupId, isPublic) => {
    const result = await updateGroupSettings(groupId, isPublic);
    if (result.success && selectedGroup) {
      setSelectedGroup(prevGroup => ({ ...prevGroup, isPublic }));
      toast.success(`تم تغيير إعدادات المجموعة إلى ${isPublic ? 'عامة' : 'خاصة'}`);
    }
    // Errors are handled in UserContext
  };

  // Handle Remove User
  const handleRemoveUser = async (groupId, username) => {
    if (username === user.username) {
      toast.error("لا يمكنك إزالة نفسك من المجموعة");
      return;
    }
    try {
      const result = await removeUserFromGroup(groupId, username);
      if (result.success && selectedGroup) {
        // Remove user from local state
        setSelectedGroup(prevGroup => ({
          ...prevGroup,
          members: prevGroup.members.filter(member => member !== username)
        }));
        toast.success(`تم إزالة ${username} من المجموعة بنجاح`);
      }
      // Errors are handled in UserContext
    } catch (error) {
      console.error('Handle remove user error:', error);
      toast.error('حدث خطأ أثناء محاولة إزالة المستخدم.');
    }
  };

  // Handle Block User
  const handleBlockUser = async (groupId, username) => {
    if (username === user.username) {
      toast.error("لا يمكنك حظر نفسك");
      return;
    }
    try {
      const result = await blockUserFromGroup(groupId, username);
      if (result.success && selectedGroup) {
        // Remove user from local state
        setSelectedGroup(prevGroup => ({
          ...prevGroup,
          members: prevGroup.members.filter(member => member !== username)
        }));
        toast.success(`تم حظر ${username} من المجموعة بنجاح`);
      }
      // Errors are handled in UserContext
    } catch (error) {
      console.error('Handle block user error:', error);
      toast.error('حدث خطأ أثناء محاولة حظر المستخدم.');
    }
  };

  // Handle Delete Group
  const handleDeleteGroup = async (groupId) => {
    try {
      const result = await deleteGroup(groupId);
      if (result.success) {
        setSelectedGroup(null);
        toast.success('تم حذف المجموعة بنجاح');
      }
      // Errors are handled in UserContext
    } catch (error) {
      console.error('Handle delete group error:', error);
      toast.error('حدث خطأ أثناء محاولة حذف المجموعة.');
    }
  };

  // Toggle Group Info Expansion
  const toggleGroupInfo = () => {
    setIsGroupInfoExpanded(!isGroupInfoExpanded);
  };

  // Toggle Drawer
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Close Drawer when clicking outside
  const handleOverlayClick = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className={`group-chat-screen ${darkMode ? 'dark-mode' : ''}`}>
      {/* Drawer Toggle Button (visible on mobile) */}
      <button className="drawer-toggle-button" onClick={toggleDrawer} aria-label="Toggle Groups">
        <FontAwesomeIcon icon={isDrawerOpen ? faTimes : faBars} />
      </button>

      {/* Overlay */}
      {isDrawerOpen && window.innerWidth < 768 && (
        <div className="drawer-overlay" onClick={handleOverlayClick}></div>
      )}

      {/* Group List Section as Drawer on Mobile */}
      <div className={`group-list ${isDrawerOpen ? 'open' : ''}`}>
        <h2>المجموعات</h2>

        {/* Subscription Message */}
        {user.subscriptionStatus !== 'active' && (
          <div className="subscription-message">
            <p>
            يمكن للمشتركين فقط انشاء مجموعة.    
              <a href="https://mo3in.com/settings" target="_blank" rel="noopener noreferrer">
                اذهب إلى الإعدادات
              </a>
            </p>
          </div>
        )}

        {user.subscriptionStatus === 'active' && (
          <form onSubmit={handleCreateGroup} className="create-group-form">
            <input 
              type="text" 
              value={newGroupName} 
              onChange={(e) => setNewGroupName(e.target.value)} 
              placeholder="اسم المجموعة" 
              required 
            />
            <select
              value={newGroupSubject}
              onChange={(e) => setNewGroupSubject(e.target.value)}
              required
            >
              <option value="">اختر المادة</option>
              {subjects.map((subject) => (
                <option key={subject._id} value={subject.name.English}>
                  {subject.name.Arabic}
                </option>
              ))}
            </select>
            <button type="submit">إنشاء مجموعة</button>
          </form>
        )}
        <ul className="group-list-items">
          {availableGroups.map(group => (
            <li 
              key={group._id} 
              className={`group-item ${selectedGroup && selectedGroup._id === group._id ? 'active' : ''} ${!group.isJoined ? 'not-joined' : ''}`}
              onClick={() => { if (group.isJoined) handleSelectGroup(group); }}
            >
              <h3>{group.name}</h3>
              <p>{subjects.find(s => s.name.English === group.subject)?.name.Arabic || group.subject}</p>
              <p>عدد الأعضاء: {group.members.length}</p>
              <p>عدد الرسائل: {group.messageCount}</p>
              {!group.isJoined && (
                <button onClick={(e) => { e.stopPropagation(); handleJoinGroup(group._id); }}>انضمام</button>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Group Chat Section */}
      <div className="group-chat">
        {selectedGroup ? (
          <>
            {/* Group Info Section */}
            <div className={`group-info ${isGroupInfoExpanded ? 'expanded' : ''}`}>
              <div className="group-info-header" onClick={toggleGroupInfo}>
                <h2>{selectedGroup.name}</h2>
                <FontAwesomeIcon icon={isGroupInfoExpanded ? faChevronUp : faChevronDown} />
              </div>
              {isGroupInfoExpanded && (
                <div className="group-info-details">
                  <p>المنشئ: {selectedGroup.creator}</p>
                  <p>{selectedGroup.isPublic ? 'مجموعة عامة' : 'مجموعة خاصة'}</p>
                  <p>عدد الأعضاء: {selectedGroup.members.length}</p>
                  <p>عدد الرسائل: {selectedGroup.messageCount}</p>
                  <p>المادة: {subjects.find(s => s.name.English === selectedGroup.subject)?.name.Arabic || selectedGroup.subject}</p>
                  {selectedGroup.isCreator && (
                    <div className="group-management">
                      <button 
                        onClick={() => handleUpdateGroupSettings(selectedGroup._id, !selectedGroup.isPublic)}
                        className="make-private-public-button"
                      >
                        {selectedGroup.isPublic ? 'جعل المجموعة خاصة' : 'جعل المجموعة عامة'}
                      </button>
                      <button 
                        className="delete-group-button"
                        onClick={() => handleDeleteGroup(selectedGroup._id)}
                      >
                        حذف المجموعة
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Message List */}
            <div className="message-list" ref={scrollRef}>
              {messages.map(message => (
                <div key={message._id} className="message-container">
                  <div className={`message ${message.isAIResponse ? 'ai-message' : 'user-message'}`}>
                    <h3 className="message-title">{message.name === 'none' ? 'أنت' : message.name}</h3>
                    <div className="message-content">
                      {renderTextWithLatex(message.content.replace(/Q:/g, 'السؤال للمعلم الذكي:').replace(/A:/g, 'رد المعلم الذكي:'))}
                    </div>
                    <div className="message-actions">
                      <button onClick={() => copyToClipboard(message.content)} aria-label="نسخ">
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="message-container">
                  <div className="ai-message">
                    <h3 className="message-title">جاري الكتابة...</h3>
                    <div className="typing-animation">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Chat Footer */}
            <div className="chat-footer">
              {/* Tab Buttons */}
              <div className="tab-buttons">
                <button 
                  className={activeTab === 'chat' ? 'active' : ''} 
                  onClick={() => setActiveTab('chat')}
                >
                  <FontAwesomeIcon icon={faComments} /> الدردشة
                </button>
                <button 
                  className={activeTab === 'members' ? 'active' : ''} 
                  onClick={() => setActiveTab('members')}
                >
                  <FontAwesomeIcon icon={faUsers} /> الأعضاء
                </button>
              </div>

              {/* Active Tab Content */}
              {activeTab === 'chat' && (
                <form onSubmit={handleSendMessage} className="send-message-form">
                  <textarea 
                    value={isAskingTeacher ? `*معلم ${newMessage}` : newMessage} 
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.startsWith('*معلم')) {
                        setIsAskingTeacher(true);
                        setNewMessage(value.replace(/^\*معلم\s*/, '')); // Remove '*معلم ' from input
                      } else {
                        setIsAskingTeacher(false);
                        setNewMessage(value);
                      }
                    }} 
                    placeholder="اكتب رسالة أو استخدم *معلم لسؤال المعلم الذكي للمساعدة" 
                    className={isAskingTeacher ? 'ask-teacher' : ''}
                    required 
                  />
                  <button type="submit" disabled={isTyping}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </form>
              )}
              {activeTab === 'members' && (
                <div className="member-management">
                  <h3>الأعضاء ({selectedGroup.members.length})</h3>
                  <ul>
                    {selectedGroup.members.map((member, index) => {
                      const isCreator = member === selectedGroup.creator;
                      const isCurrentUser = member === user.username;
                      const currentUserIsCreator = selectedGroup.creator === user.username;
                      return (
                        <li key={member}>
                          <div className="member-info">
                            <span>{member} {isCreator && "(المنشئ)"} </span>
                          </div>
                          {currentUserIsCreator && !isCreator && (
                            <div className="member-actions">
                              <button 
                                onClick={() => handleRemoveUser(selectedGroup._id, member)} 
                                className="remove-button"
                                aria-label={`إزالة ${member}`}
                              >
                                إزالة
                              </button>
                              <button 
                                onClick={() => handleBlockUser(selectedGroup._id, member)} 
                                className="block-button"
                                aria-label={`حظر ${member}`}
                              >
                                حظر
                              </button>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-group-selected">
            <p>الرجاء اختيار مجموعة للدردشة</p>
          </div>
        )}
      </div>

      {/* Toast Notifications */}
      <ToastContainer position="bottom-right" rtl={true} />
    </div>
  );
};

export default GroupChatScreen;
