import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { UserContext, useUser } from '../contexts/UserContext';
import './ForgotPasswordScreen.css';

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { forgotPassword } = useUser();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const result = await forgotPassword(email);
      if (result.success) {
        setMessage('تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور');
      } else {
        setMessage(result.error || 'حدث خطأ. يرجى المحاولة مرة أخرى.');
      }
    } catch (error) {
      console.error('Forgot password error:', error);
      setMessage('حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى لاحقًا.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <motion.div
        className="forgot-password-card"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>نسيت كلمة المرور</h2>
        {message && <div className={`message ${message.includes('تم إرسال') ? 'success' : 'error'}`}>{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="البريد الإلكتروني"
              required
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="forgot-password-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <>
                <FontAwesomeIcon icon={faPaperPlane} />
                <span>إرسال رابط إعادة التعيين</span>
              </>
            )}
          </motion.button>
        </form>
        <p className="back-to-login">
          <a href="#" onClick={() => navigate('/login')}>العودة إلى تسجيل الدخول</a>
        </p>
      </motion.div>
    </div>
  );
};

export default ForgotPasswordScreen;