import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../utils/api';
import { UserContext } from './UserContext';
import imageCompression from 'browser-image-compression';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [allChatHistories, setAllChatHistories] = useState([]); // New state for all chat histories
  const [chatHistories, setChatHistories] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  useEffect(() => {
    if (user) {
      fetchChatHistories();
    }
  }, [user]);

  const fetchSelectedSubjects = async () => {
    try {
      const response = await api.get('/api/subjects');
      if (response.status === 200) {
        setSelectedSubjects(response.data.selectedSubjects);
      }
    } catch (error) {
      console.error('Error fetching selected subjects:', error);
    }
  };

  const fetchChatHistories = async (page = 1, limit = 5) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await api.get(`/api/chatHistory?page=${page}&limit=${limit}`);
      if (response.status === 200) {
        const responseData = response.data;
        const formattedChatHistories = responseData.chatHistories.map(chat => ({
          ...chat,
          id: chat._id,
          chats: chat.chats || []
        }));
        if (page === 1) {
          setAllChatHistories(formattedChatHistories); // Update all chat histories
          setChatHistories(formattedChatHistories);
        } else {
          setAllChatHistories(prev => [...prev, ...formattedChatHistories]); // Update all chat histories
          setChatHistories(prev => [...prev, ...formattedChatHistories]);
        }
        setTotalPages(responseData.totalPages);
        setCurrentPage(responseData.currentPage);
      }
    } catch (error) {
      console.error('Error fetching chat histories:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchChatHistories = async (query) => {
    try {
      if (query.trim() === '') {
        setChatHistories(allChatHistories); // Reset to all chat histories when query is empty
      } else {
        const filteredChats = allChatHistories.filter(chat => 
          chat.chats.some(message => 
            message.question.toLowerCase().includes(query.toLowerCase()) ||
            message.answer.toLowerCase().includes(query.toLowerCase())
          )
        );
        setChatHistories(filteredChats);
      }
    } catch (error) {
      console.error('Error searching chat histories:', error);
    }
  };

  const loadMoreChatHistories = () => {
    if (currentPage < totalPages) {
      fetchChatHistories(currentPage + 1);
    }
  };

  const startNewChat = async (subject, question, photo) => {
    try {
      let photoUrl;
      if (photo) {
        photoUrl = await uploadPhoto(photo);
        console.log('Uploaded photo URL:', photoUrl); // Add this log
      }

      const answerResponse = await getAnswer(question, subject, '0', photoUrl);
      const response = await api.post('/api/chatHistory/new', {
        subject,
        question,
        answer: answerResponse.result,
        threadId: answerResponse.createdThread.toString(),
        imageUrl: photoUrl,
      });

      if (response.status === 201) {
        await fetchChatHistories();
        return {
          chatId: response.data._id,
          answer: answerResponse.result,
          newChatId: response.data.chats[0]._id,
          imageUrl: photoUrl, // Add this line
        };
      }
    } catch (error) {
      console.error('Error starting new chat:', error);
      throw error;
    }
  };

  const addChatEntry = async (chatHistoryId, question, photo) => {
    try {
      let photoUrl;
      if (photo) {
        photoUrl = await uploadPhoto(photo);
        console.log('Uploaded photo URL:', photoUrl); // Add this log
      }

      const existingChat = chatHistories.find(chat => chat.id === chatHistoryId);
      const answerResponse = await getAnswer(question, existingChat.subject, chatHistoryId, photoUrl);

      const response = await api.put(`/api/chatHistory/${chatHistoryId}/chat`, {
        question,
        answer: answerResponse.result,
        imageUrl: photoUrl,
      });

      if (response.status === 200) {
        await fetchChatHistories();
        return {
          answer: answerResponse.result,
          chatId: chatHistoryId,
          newChatId: response.data.chats[response.data.chats.length - 1]._id,
          imageUrl: photoUrl, // Add this line
        };
      }
    } catch (error) {
      console.error('Error adding chat entry:', error);
      throw error;
    }
  };

  const deleteChat = async (id) => {
    console.log('Attempting to delete chat with ID:', id); // Add this line
    try {
      if (!id) {
        console.error('Invalid chat ID:', id);
        return;
      }
      // Immediately remove the chat from the local state
      setChatHistories(prev => {
        console.log('Current chat histories:', prev); // Add this line
        return prev.filter(chat => {
          console.log('Comparing:', chat.id, id); // Add this line
          return chat.id !== id;
        });
      });
      
      // Make the API call to delete the chat
      const response = await api.delete(`/api/chatHistory/${id}`);
      console.log('Delete API response:', response); // Add this line

      // Refresh chat histories
      await fetchChatHistories();
    } catch (error) {
      console.error('Error deleting chat:', error);
      // If there's an error, revert the local change by refreshing chat histories
      await fetchChatHistories();
      throw error;
    }
  };

  const deleteAllChats = async () => {
    try {
      await api.delete('/api/chatHistory');
      await fetchChatHistories();
    } catch (error) {
      console.error('Error deleting all chats:', error);
      throw error;
    }
  };

  const toggleLike = async (chatHistoryId, chatId, isLike) => {
    try {
      console.log('Toggling like for chat history ID:', chatHistoryId);
      console.log('Toggling like for chat entry ID:', chatId);
      console.log('isLiked:', isLike);

      const response = await api.put(`/api/chatAction/${chatHistoryId}/toggleLike`, {
        chatId,
        isLiked: isLike,
      });

      if (response.status === 200) {
        setChatHistories(prev =>
          prev.map(ch =>
            ch._id === chatHistoryId 
              ? {
                  ...ch,
                  chats: ch.chats.map(chat =>
                    chat._id === chatId
                      ? { ...chat, isLiked: isLike, isDisliked: !isLike, toggle: true }
                      : chat
                  )
                }
              : ch
          )
        );
      }
    } catch (error) {
      console.error('Error toggling like/dislike:', error);
      throw error;
    }
  };

  const getChatHistoryById = async (id) => {
    try {
      const url = `/api/chatHistory`;
      console.log('Fetching chat histories from:', url);
      console.log('Full URL:', `${api.defaults.baseURL}${url}`);
      const response = await api.get(url);
      if (response.status === 200) {
        console.log('Chat histories response:', response.data);
        const chatHistory = response.data.chatHistories.find(chat => chat._id === id);
        if (chatHistory) {
          console.log('Found chat history:', chatHistory);
          return chatHistory;
        } else {
          console.log('Chat history not found in the response');
          return null;
        }
      } else {
        throw new Error(`Failed to get chat histories. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error getting chat history:', error);
      console.log('Full error object:', JSON.stringify(error, null, 2));
      return null;
    }
  };

  // Helper functions (implement these as needed)
  const uploadPhoto = async (photo) => {
    try {
      const compressedFile = await compressImage(photo);
      const formData = new FormData();
      formData.append('file', compressedFile, compressedFile.name);

      const response = await api.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log('Upload response:', response.data); // Add this log
        return response.data.photoUrl; // Change this line
      } else {
        throw new Error(`Failed to upload photo. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const getAnswer = async (question, type, threadId, photoUrl) => {
    try {
      const response = await api.post('api/getAnswer', {
        query: question,
        type: type,
        threadId: threadId,
        imageUrl: photoUrl,
        studyType: 'normal', // You might want to make this configurable
      });

      if (response.status === 200) {
        return {
          result: response.data.result,
          createdThread: response.data.createdThread,
        };
      } else {
        throw new Error(`Failed to get answer. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error getting answer:', error);
      if (error.response && error.response.status === 429) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  return (
    <ChatContext.Provider
      value={{
        chatHistories,
        allChatHistories, // Add this to the context value
        isLoading,
        fetchChatHistories,
        searchChatHistories,
        loadMoreChatHistories,
        startNewChat,
        addChatEntry,
        deleteChat,
        deleteAllChats,
        toggleLike,
        getChatHistoryById,
        fetchSelectedSubjects,
        selectedSubjects,
        uploadPhoto,
        getAnswer, // Add this line

      }}
    >
      {children}
    </ChatContext.Provider>
  );
};