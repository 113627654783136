import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock, faGraduationCap, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../utils/api';
import './RegisterScreen.css';

const RegisterScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [classOptions, setClassOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await api.get('/api/classes');
      setClassOptions(response.data.classes.map(c => ({
        name: getClassArabicName(c),
        value: c
      })));
    } catch (error) {
      console.error('Failed to fetch classes:', error);
      setErrorMessage('فشل في تحميل الصفوف. يرجى المحاولة مرة أخرى.');
    }
  };

  const getClassArabicName = (classValue) => {
    const arabicNames = ['الأول', 'الثاني', 'الثالث', 'الرابع', 'الخامس', 'السادس', 'السابع', 'الثامن', 'التاسع', 'العاشر', 'الحادي عشر', 'الثاني عشر'];
    return arabicNames[classValue - 1] || '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("كلمات المرور غير متطابقة");
      return;
    }
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.post('/api/register', {
        email,
        password,
        classs: selectedClass,
        firstName: name,
      });
      if (response.status === 201) {
        navigate('/otp-verification', { state: { email } });
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'فشل التسجيل. يرجى المحاولة مرة أخرى.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container">
      <motion.div
        className="register-card"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2>التسجيل الجديد</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <FontAwesomeIcon icon={faUser} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="اسم المستخدم"
              required
            />
          </div>
          <div className="input-group">
            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="البريد الإلكتروني"
              required
            />
          </div>
          <div className="input-group">
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="كلمة المرور"
              required
            />
          </div>
          <div className="input-group">
            <FontAwesomeIcon icon={faLock} className="input-icon" />
            <motion.input
              whileFocus={{ scale: 1.05 }}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="تأكيد كلمة المرور"
              required
            />
          </div>
          <div className="input-group">
            <FontAwesomeIcon icon={faGraduationCap} className="input-icon" />
            <motion.select
              whileFocus={{ scale: 1.05 }}
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              required
            >
              <option value="">اختر الصف</option>
              {classOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </motion.select>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="register-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loader"></div>
            ) : (
              <>
                <FontAwesomeIcon icon={faUserPlus} />
                <span>تسجيل</span>
              </>
            )}
          </motion.button>
        </form>
        <p className="login-link">
          لديك حساب بالفعل؟ <Link to="/login">تسجيل الدخول</Link>
        </p>
      </motion.div>
    </div>
  );
};

export default RegisterScreen;