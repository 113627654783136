// PaymentTab.jsx
import React, { useState, useContext } from 'react';
import api from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../contexts/ThemeContext';
import './PaymentTab.css';

const PaymentTab = ({ subscriptionStatus, paymentHistory, onRefresh }) => {
  const { darkMode } = useContext(ThemeContext);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleSubscribe = async () => {
    if (!selectedSubscription) return;
    setIsLoading(true);
    setIsRedirecting(true);
    try {
      const response = await api.post('/api/subscribe', { 
        subscriptionType: selectedSubscription,
        amount: 6,
        currency: 'OMR'
      });
      if (response.data.requiresRedirect) {
        window.location.href = response.data.redirectUrl;
      } else {
        showErrorMessage('فشل الاشتراك');
      }
    } catch (error) {
      showErrorMessage(`خطأ: ${error.message}`);
    } finally {
      setIsLoading(false);
      setIsRedirecting(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ar-OM');
  };

  const showSuccessMessage = (message) => {
    // Implement your success message display logic here
    console.log('Success:', message);
  };

  const showErrorMessage = (message) => {
    // Implement your error message display logic here
    console.error('Error:', message);
  };

  return (
    <div className={`payment-tab ${darkMode ? 'dark-mode' : ''}`}>
      <h2>حالة الاشتراك</h2>
      <div className="subscription-status">
        <p>
          <FontAwesomeIcon 
            icon={subscriptionStatus.subscriptionStatus === 'active' ? faCheckCircle : faTimesCircle} 
            className="status-icon"
          />
          <strong> الحالة:</strong> {subscriptionStatus.subscriptionStatus === 'active' ? 'نشط' : 'غير نشط'}
        </p>
        <p><strong>النوع:</strong> {subscriptionStatus.subscriptionType}</p>
        <p><strong>تاريخ الانتهاء:</strong> {formatDate(subscriptionStatus.subscriptionEndDate)}</p>
      </div>

      {subscriptionStatus.subscriptionStatus !== 'active' && (
        <div className="subscription-form">
          <h3>اشترك الآن</h3>
          <select 
            onChange={(e) => setSelectedSubscription(e.target.value)} 
            className="subscription-select" 
            disabled={isLoading || isRedirecting}
            value={selectedSubscription || ''}
            aria-label="اختر خطة الاشتراك"
          >
            <option value="">اختر خطة</option>
            <option value="monthly">شهري - 6 ريال عماني</option>
          </select>
          <button 
            onClick={handleSubscribe} 
            className="btn-primary" 
            disabled={isLoading || isRedirecting || !selectedSubscription}
            aria-label="اشترك الآن"
          >
            {isRedirecting ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin /> جاري التحويل إلى بوابة الدفع...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCreditCard} /> اشترك الآن
              </>
            )}
          </button>
        </div>
      )}

      <h3>سجل المدفوعات</h3>
      <div className="payment-history">
        {paymentHistory.length > 0 ? (
          paymentHistory.map((payment, index) => (
            <div key={index} className="payment-history-item">
              <p><strong>المبلغ:</strong> {payment.amount} ريال عماني - {payment.description}</p>
              <p><strong>التاريخ:</strong> {formatDate(payment.date)}</p>
              <p><strong>الحالة:</strong> {payment.status}</p>
            </div>
          ))
        ) : (
          <p className="no-payments">لا توجد مدفوعات مسجلة.</p>
        )}
      </div>
    </div>
  );
};

export default PaymentTab;
