// MySubjectsTab.jsx
import React, { useState, useEffect, useContext, useCallback } from 'react';
import api from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../contexts/ThemeContext';
import './MySubjectsTab.css';

const MySubjectsTab = ({ subjects, selectedSubjects, onRefresh }) => {
  const { darkMode } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [localSubjects, setLocalSubjects] = useState([]);
  const [localSelectedSubjects, setLocalSelectedSubjects] = useState([]);

  const removeDuplicates = useCallback((data) => {
    if (Array.isArray(data)) {
      return removeDuplicatesFromArray(data);
    } else if (typeof data === 'object' && data !== null) {
      return removeDuplicatesFromObject(data);
    } else {
      console.error('Expected an array or object, but received:', data);
      return [];
    }
  }, []);

  useEffect(() => {
    setLocalSubjects(removeDuplicates(subjects));
    setLocalSelectedSubjects(removeDuplicates(selectedSubjects || []));
  }, [subjects, selectedSubjects, removeDuplicates]);

  const removeDuplicatesFromArray = (list) => {
    const uniqueSubjects = {};
    list.forEach(subject => {
      if (subject && subject.name) {
        const key = `${subject.name.English}_${subject.name.Arabic}`;
        uniqueSubjects[key] = subject;
      }
    });
    return Object.values(uniqueSubjects);
  };

  const removeDuplicatesFromObject = (obj) => {
    const subjectsList = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
        subjectsList.push(...obj[key]);
      }
    }
    return removeDuplicatesFromArray(subjectsList);
  };

  const isSubjectSelected = useCallback((subject) => {
    return localSelectedSubjects.some(
      selected => 
        selected.name.English === subject.name.English &&
        selected.name.Arabic === subject.name.Arabic
    );
  }, [localSelectedSubjects]);

  const toggleSubjectSelection = useCallback((subject) => {
    setLocalSelectedSubjects(prev => {
      let newSelectedSubjects;
      if (isSubjectSelected(subject)) {
        newSelectedSubjects = prev.filter(
          selected => 
            selected.name.English !== subject.name.English ||
            selected.name.Arabic !== subject.name.Arabic
        );
      } else {
        newSelectedSubjects = [...prev, subject];
      }
      updateSelectedSubjects(newSelectedSubjects);
      return newSelectedSubjects;
    });
  }, [isSubjectSelected]);

  const selectAll = useCallback(() => {
    setLocalSelectedSubjects(localSubjects);
    updateSelectedSubjects(localSubjects);
  }, [localSubjects]);

  const unselectAll = useCallback(() => {
    setLocalSelectedSubjects([]);
    updateSelectedSubjects([]);
  }, []);

  const updateSelectedSubjects = useCallback(async (newSelectedSubjects) => {
    setIsLoading(true);
    try {
      await api.put('/api/update-selected-subjects', { selectedSubjects: newSelectedSubjects });
      showSuccessToast('تم تحديث المواد المفضلة بنجاح');
      await saveSelectedSubjectsToLocalStorage(newSelectedSubjects);
      onRefresh();
    } catch (error) {
      console.error('Failed to update selected subjects:', error);
      showErrorToast('فشل تحديث المواد المفضلة');
    } finally {
      setIsLoading(false);
    }
  }, [onRefresh]);

  const saveSelectedSubjectsToLocalStorage = useCallback(async (selectedSubjects) => {
    localStorage.setItem('selectedSubjects', JSON.stringify(selectedSubjects));
  }, []);

  const showErrorToast = (message) => {
    // Implement your toast notification here
    console.error(message);
  };

  const showSuccessToast = (message) => {
    // Implement your toast notification here
    console.log(message);
  };

  return (
    <div className={`my-subjects-tab ${darkMode ? 'dark-mode' : ''}`}>
      <h2>المواد الدراسية</h2>
      <p>اختر المواد التي ترغب في دراستها</p>
      <div className="button-group">
        <button onClick={selectAll} disabled={isLoading} className="btn-secondary">تحديد الكل</button>
        <button onClick={unselectAll} disabled={isLoading} className="btn-secondary">إلغاء تحديد الكل</button>
      </div>
      {isLoading ? (
        <div className="loading">جاري التحميل...</div>
      ) : (
        <div className="subjects-container">
          <ul className="subjects-list">
            {localSubjects.map((subject) => (
              <li key={`${subject.name.English}_${subject.name.Arabic}`}>
                <label className="subject-item">
                  <input
                    type="checkbox"
                    checked={isSubjectSelected(subject)}
                    onChange={() => toggleSubjectSelection(subject)}
                    aria-label={`اختر مادة ${subject.name.Arabic}`}
                  />
                  <span className="subject-name">{subject.name.Arabic}</span>
                  {isSubjectSelected(subject) && (
                    <FontAwesomeIcon icon={faCheck} className="subject-selected-icon" />
                  )}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default React.memo(MySubjectsTab);
