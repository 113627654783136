export const subjectOptions = {
  'الاحياء': 'Biology',
  'الرياضيات': 'Mathematics',
  'الفيزياء': 'Physics',
  'الكيمياء': 'Chemistry',
};

export const allOptions = {
  ...subjectOptions,
  // Add any additional subjects here if needed
};

export const initializeSubjectOptions = async () => {
  // Implement this function to load subject options from local storage or API
  // For now, we'll just use the static subjectOptions
  return subjectOptions;
};

export const initializeAllOptions = async () => {
  // Implement this function to load all options from local storage or API
  // For now, we'll just use the static allOptions
  return allOptions;
};