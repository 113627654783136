// SettingsScreen.jsx
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../utils/api';
import ProfileAndPaymentTab from './SettingsTabs/ProfileAndPaymentTab';
import MySubjectsTab from './SettingsTabs/MySubjectsTab';
import { ThemeContext } from '../contexts/ThemeContext';
import './SettingsScreen.css';

const SettingsScreen = () => {
  const { darkMode } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('account');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    userProfile: {},
    subjects: [],
    selectedSubjects: [],
    subscriptionStatus: {},
    paymentHistory: [],
    classOptions: [],
  });

  useEffect(() => {
    loadAllData();
  }, []);

  const loadAllData = async () => {
    setIsLoading(true);
    try {
      const [
        profileResponse,
        subjectsResponse,
        subscriptionResponse,
        paymentResponse,
        classesResponse
      ] = await Promise.all([
        api.get('/api/profile'),
        api.get('/api/subjects'),
        api.get('/api/subscription-status'),
        api.get('/api/payment-history'),
        api.get('/api/classes')
      ]);

      setData({
        userProfile: profileResponse.data,
        subjects: subjectsResponse.data.subjects,
        selectedSubjects: subjectsResponse.data.selectedSubjects,
        subscriptionStatus: subscriptionResponse.data,
        paymentHistory: paymentResponse.data,
        classOptions: classesResponse.data.classes.map(c => ({
          name: getClassArabicName(c),
          value: c,
        })),
      });
    } catch (error) {
      console.error('Error loading data:', error);
      // Optionally, set an error state here to inform the user
    } finally {
      setIsLoading(false);
    }
  };

  const getClassArabicName = (classValue) => {
    const classNames = {
      12: 'الثاني عشر',
      11: 'الحادي عشر',
      10: 'العاشر',
      9: 'التاسع',
      8: 'الثامن',
      7: 'السابع',
      6: 'السادس',
      5: 'الخامس',
      4: 'الرابع',
      3: 'الثالث',
      2: 'الثاني',
      1: 'الأول'
    };
    return classNames[classValue] || '';
  };

  const tabs = [
    { id: 'account', icon: faUser, title: 'الحساب والاشتراك', component: ProfileAndPaymentTab },
    { id: 'subjects', icon: faBook, title: 'المواد', component: MySubjectsTab },
  ];

  const handleTabKeyPress = (e, tabId) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setActiveTab(tabId);
    }
  };

  return (
    <div className={`settings-screen ${darkMode ? 'dark-mode' : ''}`}>
      {/* Optional: Header Section */}
      {/* <div className="settings-header">
        <button className="home-button" aria-label="الصفحة الرئيسية">
          <FontAwesomeIcon icon={faHome} />
        </button>
        <h1>إعدادات الحساب</h1>
      </div> */}

      <div className="tab-buttons" role="tablist" aria-label="إعدادات الحساب">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
            onKeyPress={(e) => handleTabKeyPress(e, tab.id)}
            role="tab"
            aria-selected={activeTab === tab.id}
            aria-controls={`tab-panel-${tab.id}`}
            id={`tab-${tab.id}`}
          >
            <FontAwesomeIcon icon={tab.icon} />
            <span>{tab.title}</span>
          </button>
        ))}
      </div>

      {isLoading ? (
        <div className="loading" role="status" aria-live="polite">
          جاري التحميل...
        </div>
      ) : (
        <div className="tab-content-container">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              className="tab-content"
              id={`tab-panel-${activeTab}`}
              role="tabpanel"
              aria-labelledby={`tab-${activeTab}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {activeTab === 'account' && (
                <ProfileAndPaymentTab
                  userProfile={data.userProfile}
                  classOptions={data.classOptions}
                  subscriptionStatus={data.subscriptionStatus}
                  paymentHistory={data.paymentHistory}
                  onRefresh={loadAllData}
                />
              )}
              {activeTab === 'subjects' && (
                <MySubjectsTab
                  subjects={data.subjects}
                  selectedSubjects={data.selectedSubjects}
                  onRefresh={loadAllData}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default SettingsScreen;
